import React from 'react'
import { Form, Button } from "react-bootstrap";
import { IFacilityFormValues } from "../Interfaces/LeaseApp";
import { useForm, UseFormGetValues } from "react-hook-form";
import LeasePartial from "./LeasePartial";
import FacilityQuestions from "./FacilityQuestions";

interface IFacilityProps {
    data: IFacilityFormValues;
    submitting: boolean;
    saving: boolean;
    onSubmit: (data: IFacilityFormValues) => void;
    onSaveDraft: (data: IFacilityFormValues) => any;
    onCancel: (id: number) => any;
    purchaseRequestId: number;
    leaseTypeId: number;
}

const FacilityLeaseForm = ({
    data,
    submitting,
    saving,
    onSubmit,
    onSaveDraft,
    onCancel,
    purchaseRequestId,
    leaseTypeId
}: IFacilityProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<IFacilityFormValues>({ 
        defaultValues: data
        });

     
    return (
        <Form onSubmit={handleSubmit(onSubmit)} >
            <div className="lease-general p-3 mb-2">
                <LeasePartial register={register} errors={errors} data={data} leaseTypeId={leaseTypeId} />
            </div>
            <FacilityQuestions register={register} errors={errors} data={data} />

            <div className="mt-2 d-flex justify-content-center justify-content-sm-end">
                <Button
                    disabled={submitting}
                    className="m-1 nw-btn btn-ghost"
                    onClick={() => onCancel(purchaseRequestId)}
                >
                    Cancel
                </Button>
                {!data.isSubmitted &&
                    <Button onClick={() => onSaveDraft(getValues())}
                        disabled={saving}
                        type="button"
                        className="m-1 btn nw-btn btn-cerulean"
                    >
                        {saving ? "Saving Draft..." : "Save As Draft"}
                    </Button>
                }
                <Button
                    disabled={submitting}
                    type="submit"
                    className="m-1 btn nw-btn btn-cerulean"
                >
                    {submitting ? "Saving..." : "Save & Close"}
                </Button>
            </div>


        </Form>
    );
};

export default FacilityLeaseForm;
