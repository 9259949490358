import React from 'react';
import { useState, Fragment, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import PurchaseRequestTable from "../Components/PurchaseRequestTable";
import PurchaseRequestSearch from "../Components/PurchaseRequestSearch";
import LeasePagination from "../Components/LeasePagination";

import axios from "axios";

const url: string = window.location.origin + "/api/purchaserequest/search";

const PurchaseRequestList = () => {
  const [currentPrs, setCurrentPrs] = useState([]);
  const [pageParams, setPageParams] = useState({
    searchType: "0",
    searchValue: "",
    currentPage: 1,
    pageLimit: 10,
    totalRecords: 0,
  });
  const [loading, setLoading] = useState(true);

  const handleSearchSubmit = (searchType: string, searchValue: string) => {
    setPageParams((prevState: any) => {
      return Object.assign({}, prevState, {
        searchType,
        searchValue,
      });
    });
  };

  const handlePageChange = (currentPage: number) => {
    setPageParams((prevState: any) => {
      return Object.assign({}, prevState, {
        currentPage,
      });
    });
  };

  const handleLimitChange = (pageLimit: number) => {
    setPageParams((prevState: any) => {
      return Object.assign({}, prevState, {
        pageLimit,
      });
    });
  };

  useEffect(() => {
    let params = {
      searchCriteria: pageParams.searchType,
      searchValue: pageParams.searchValue,
      currentPage: pageParams.currentPage,
      pageLimit: pageParams.pageLimit,
    };
    getPagedPrData(params);
  }, [
    pageParams.searchType,
    pageParams.searchValue,
    pageParams.currentPage,
    pageParams.pageLimit,
  ]);

  const getPagedPrData = (params: {
    searchCriteria: string;
    searchValue: string;
    currentPage: number;
    pageLimit: number;
  }) => {
    const { searchCriteria, searchValue, currentPage, pageLimit } = params;
    const offset = (currentPage - 1) * pageLimit;
    setLoading(true);
    axios
      .get(url, {
        params: {
          searchCriteria,
          searchValue,
          offset,
          limit: pageLimit,
        },
      })
      .then((response) => {
        setCurrentPrs(response.data.purchaseRequests);
        setPageParams((prevState: any) => {
          let totalRecords = response.data.totalRecords;
          return Object.assign({}, prevState, {
            totalRecords,
          });
        });
        setLoading(false);
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 404) {
          // no pr records found, set currentPrs to []
          setCurrentPrs([]);
        }
        setLoading(false);
      });
  };

  return (
    <Container>
      <Fragment>
        <Row className="justify-content-center">
          <Col xs={6} className="m-5 text-center">
            To create a new lease, search for the Purchase Request Number in the
            table below. If you need assistance, please contact{" "}
            <a href="mailto:mwalters@northwindgrp.com">
              mwalters@northwindgrp.com
            </a>
            .
          </Col>
        </Row>
        <Row className="justify-content-end mt-5">
        <Col xs={12}>
            <PurchaseRequestSearch
              handleSearchSubmit={handleSearchSubmit}
              searchType={pageParams.searchType}
              searchValue={pageParams.searchValue}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12}>
            <PurchaseRequestTable data={currentPrs} loading={loading} />
          </Col>
        </Row>
        <Row className="justify-content-start">
          <Col xs={6}>
            <LeasePagination
              currentPage={pageParams.currentPage}
              pageLimit={pageParams.pageLimit}
              totalRecords={pageParams.totalRecords}
              pageNeighbours={1}
              onPageChange={handlePageChange}
            />

            <Form.Group style={{ float: "left" }} className="p-2">
              <Form.Select
                size="sm"
                defaultValue={pageParams.pageLimit}
                onChange={(e) => handleLimitChange(Number(e.target.value))}
              >
                {/*<option value={1} >1</option> */}
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </Form.Select>
            </Form.Group>
            <label className="p-2">items per page</label>
          </Col>
        </Row>
      </Fragment>
    </Container>
  );
};

export default PurchaseRequestList;
