import React from 'react'
import { Fragment, useEffect, useState } from "react";
import { IFacilityFormValues } from "../Interfaces/LeaseApp";
import FacilityLeaseForm from "./FacilityLeaseForm";

import axios from "axios";

interface IFacilityProps {
    id: number;
    isUpdate: boolean;
    submitting: boolean;
    saving: boolean;
    onSubmit: (data: IFacilityFormValues) => void;
    onSaveDraft: (data: IFacilityFormValues) => void;
    onCancel: (id: number) => any;
    purchaseRequestId: number;
    leaseTypeId: number;
}

const FacilityLease = ({
    id,
    isUpdate,
    submitting,
    saving,
    onSubmit,
    onSaveDraft,
    onCancel,
    purchaseRequestId,
    leaseTypeId
}: IFacilityProps) => {
    const [data, setData] = useState<IFacilityFormValues>();

    useEffect(() => {
        if (isUpdate) {
            const url = `${window.location.origin}/api/lease/facility/${id}`;

            const fetchData = async () => {
                try {
                    const result = await axios.get(url);
                    if (result.data) {
                        result.data.leaseSigningDate =
                            result.data.leaseSigningDate?.toString().substr(0, 10);
                        result.data.leaseCommencementDate =
                            result.data.leaseCommencementDate?.toString().substr(0, 10);
                        result.data.leaseExpirationDate =
                            result.data.leaseExpirationDate?.toString().substr(0, 10);
                        result.data.firstPaymentDueDate =
                            result.data.firstPaymentDueDate?.toString().substr(0, 10);
                    }

                    setData(result.data);
                } catch (error) {
                    console.log(error);
                }
            };

            fetchData();
        } else {
            let newData: any = {
                discountRate: 0,
                earlyTerminationPenaltyAmount: 0.0, 
                initialDirectCostAmount: 0, 
                leasePaymentComponentAmount: 0,
                leasePaymentsAfterAmount: 0,
                leasePaymentsAmount: 0,
                leasePaymentsBeforeAmount: 0,
                leasePrepaymentAmount: 0, 
                leaseTerm: 0,
                nonEarlyTerminationPenaltyAmount: 0,
                nonLeasePaymentComponentAmount: 0,
                nonRenewalPenaltyAmount: 0,
                payFrequencyTypeId: 1,
                payPeriodTypeId: 1,
                paymentPeriodFuturePayments: 0,
                paymentPeriodIfPenalty: 0,
                percentPaymentsRightToUse: 0
            };
            setData(newData);
        }
    }, [isUpdate, id]);

    return (
        <Fragment>
            {data ? (
                <FacilityLeaseForm
                    leaseTypeId={leaseTypeId} 
                    data={data}
                    submitting={submitting}
                    saving={saving}
                    onSubmit={onSubmit}
                    onSaveDraft={onSaveDraft}
                    onCancel={onCancel}
                    purchaseRequestId={purchaseRequestId}
                />
            ) : (
                "Loading form data..."
            )}
        </Fragment>
    );
};

export default FacilityLease;
