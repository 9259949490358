import React from 'react';
import { Fragment } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { IEquipmentFormValues } from "../Interfaces/LeaseApp";

const requiredMessage: string = "This field is required";

interface IEquipmentPartialProps {
    data: IEquipmentFormValues | undefined;
    register: any;
    errors: any;
    leaseTypeId: number; 
}
 

function EquipmentPartial(props: IEquipmentPartialProps) {
    return (
        <Fragment>
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-5" controlId="modelNumber">
                        <Form.Label>Model #</Form.Label>
                        <Form.Control
                            {...props.register("modelNumber", {
                                required: requiredMessage,
                                minLength: {
                                    value: 1,
                                    message: "Min-length = 1",
                                },
                            })}
                            defaultValue={props.data?.modelNumber}
                            type="text"
                            placeholder="Model Number"
                            isInvalid={!!props.errors.modelNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.modelNumber?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-5" controlId="serialNumber">
                        <Form.Label>Serial #</Form.Label>
                        <Form.Control
                            {...props.register("serialNumber", {
                                required: requiredMessage,
                                minLength: {
                                    value: 1,
                                    message: "Min-length = 1",
                                },
                            })}
                            defaultValue={props.data?.serialNumber}
                            type="text"
                            placeholder="Serial Number"
                            isInvalid={!!props.errors.serialNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.serialNumber?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-5" controlId="periodOfPerformanceStart">
                        <Form.Label>Project Period of Performance Start</Form.Label>
                        <Form.Control
                            {...props.register("periodOfPerformanceStart", {})}
                            defaultValue={props.data?.periodOfPerformanceStart}
                            type="date"
                            placeholder="Period of Performance"
                            isInvalid={!!props.errors.periodOfPerformanceStart}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.periodOfPerformanceStart?.message}
                        </Form.Control.Feedback>
                        <i></i>
                    </Form.Group>
                </Col>
                <Col sm={6}>
                    <Form.Group className="mb-5" controlId="periodOfPerformanceEnd">
                        <Form.Label>Project Period of Performance End</Form.Label>
                        <Form.Control
                            {...props.register("periodOfPerformanceEnd", {})}
                            defaultValue={props.data?.periodOfPerformanceEnd}
                            type="date"
                            placeholder="Period of Performance"
                            isInvalid={!!props.errors.periodOfPerformanceEnd}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.periodOfPerformanceEnd?.message}
                        </Form.Control.Feedback>
                        <i></i>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <Form.Group className="mb-5" controlId="isLeaseValueExceedFmv">
                        <Form.Label>
                            Present Value of Lease Payments Plus Residual Value Guarantee
                            Exceed Substantially All of FMV
                        </Form.Label>
                        <Form.Check
                            type="switch"
                            label={props.data?.isLeaseValueExceedFmv ? "Yes" : "No"}
                            onClick={(e) =>
                            ((e.target as HTMLFormElement).labels[1].innerHTML = (
                                e.target as HTMLFormElement
                            ).checked
                                ? "Yes"
                                : "No")
                            }
                            aria-label="isLeaseValueExceedFmv"
                            defaultChecked={props.data?.isLeaseValueExceedFmv}
                            {...props.register("isLeaseValueExceedFmv", {})}
                            isInvalid={!!props.errors.isLeaseValueExceedFmv}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.isLeaseValueExceedFmv?.message}
                        </Form.Control.Feedback>
                        <i></i>
                    </Form.Group>

                    {props.leaseTypeId == 1 || props.leaseTypeId == 3 &&
                        <Form.Group className="mb-5" controlId="isEvergreen">
                        <Form.Label>Evergreen Lease (Lease less than 12 month/month to month)</Form.Label>
                            <Form.Check
                                type="switch"
                                label={props.data?.isEvergreen ? "Yes" : "No"}
                                onClick={(e) =>
                                ((e.target as HTMLFormElement).labels[1].innerHTML = (
                                    e.target as HTMLFormElement
                                ).checked
                                    ? "Yes"
                                    : "No")
                                }
                                aria-label="isEvergreen"
                                defaultChecked={props.data?.isEvergreen}
                                {...props.register("isEvergreen", {})}
                                isInvalid={!!props.errors.isEvergreen}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.isEvergreen?.message}
                            </Form.Control.Feedback>
                        <i>Lease/Rental reasonably sure not to go beyond 12 months.</i>
                        </Form.Group>
                    }

                    <Form.Group className="mb-5" controlId="isAssetOfSpecializedNature">
                        <Form.Label>
                            Asset is of Specialized Nature That Lessor Has No Alternative to
                            Use Asset at End of Lease
                        </Form.Label>
                        <Form.Check
                            type="switch"
                            label={props.data?.isAssetOfSpecializedNature ? "Yes" : "No"}
                            onClick={(e) =>
                            ((e.target as HTMLFormElement).labels[1].innerHTML = (
                                e.target as HTMLFormElement
                            ).checked
                                ? "Yes"
                                : "No")
                            }
                            aria-label="isAssetOfSpecializedNature"
                            defaultChecked={props.data?.isAssetOfSpecializedNature}
                            {...props.register("isAssetOfSpecializedNature", {})}
                            isInvalid={!!props.errors.isAssetOfSpecializedNature}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.isAssetOfSpecializedNature?.message}
                        </Form.Control.Feedback>
                        <i></i>
                    </Form.Group>
                </Col>

                <Col sm={6}>
                    <Form.Group className="mb-5" controlId="projectChargeNumber">
                        <Form.Label>Project Charge #</Form.Label>
                        <Form.Control
                            {...props.register("projectChargeNumber", {
                                minLength: {
                                    value: 1,
                                    message: "Min-length = 1",
                                },
                            })}
                            defaultValue={props.data?.projectChargeNumber}
                            type="text"
                            placeholder="Project Charge Number"
                            isInvalid={!!props.errors.projectChargeNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.projectChargeNumber?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </Fragment>
    );
}

export default EquipmentPartial;
