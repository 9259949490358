import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Header from "./Components/Header";
import PurchaseRequestList from "./Pages/PurchaseRequestList";
import PurchaseRequestDetail from "./Pages/PurchaseRequestDetail";
import UpdateLease from "./Pages/UpdateLease";
import CreateLease from "./Pages/CreateLease";

import config from "./oktaConfig";
import "./App.css"; 
const oktaAuth = new OktaAuth(config.oidc);

function App() {
    const history = useHistory();

    const restoreOriginalUrl = async (_oktaAuth: any, originalUri: any) => {
        history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    };

    const Web = (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUrl}>
            <div className="App">
                <Header />

                <Switch>
                    <Route path="/login/callback" component={LoginCallback} />
                    <SecureRoute exact path="/" component={PurchaseRequestList} />
                    <SecureRoute path="/detail/:id" component={PurchaseRequestDetail} />
                    <SecureRoute
                        path="/create-lease/:prId/:leasetypeid"
                        component={CreateLease}
                    />
                    <SecureRoute
                        path="/update-lease/:prId/:leaseid/:leasetypeid"
                        component={UpdateLease}
                    />
                </Switch>
            </div>
        </Security>
    )

     
     
    return Web;
     
}

export default App;
