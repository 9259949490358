import React from 'react';
import { Row, Col, Form } from "react-bootstrap";
import { IProjectFleetFormValues } from "../Interfaces/LeaseApp";

const requiredMessage: string = "This field is required";

interface IProjectFleetPartialProps {
  data: IProjectFleetFormValues | undefined;
  register: any;
  errors: any;
}

function ProjectFleetPartial(props: IProjectFleetPartialProps) {
  return (
    <Row>
      <Col sm={12}>
        <Form.Group className="mb-5" controlId="vehicleIdentificationNumber">
          <Form.Label>Vehicle Identification #</Form.Label>
          <Form.Control
            {...props.register("vehicleIdentificationNumber", {
              required: requiredMessage,
              minLength: {
                value: 1,
                message: "Min-length = 1",
              },
            })}
            defaultValue={props.data?.vehicleIdentificationNumber}
            type="text"
            placeholder="Vehicle Identification #"
            isInvalid={!!props.errors.vehicleIdentificationNumber}
          />
          <Form.Control.Feedback type="invalid">
            {props.errors.vehicleIdentificationNumber?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  );
}

export default ProjectFleetPartial;
