import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { IEquipmentFormValues } from "../Interfaces/LeaseApp";
import EquipmentLeaseForm from "./EquipmentLeaseForm";

interface IEquipmentProps {
    id: number;
    isUpdate: boolean;
    submitting: boolean;
    saving: boolean;
    onSubmit: (data: IEquipmentFormValues) => void;
    onSaveDraft: (data: IEquipmentFormValues) => void;
    onCancel: (id: number) => any;
    purchaseRequestId: number;
    leaseTypeId: number;
}

const EquipmentLease = ({
    id,
    isUpdate,
    submitting,
    saving,
    onSubmit,
    onSaveDraft,
    onCancel,
    purchaseRequestId,
    leaseTypeId

}: IEquipmentProps) => {
    const [data, setData] = useState<IEquipmentFormValues>();

    useEffect(() => {
        if (isUpdate) {
            const url = `${window.location.origin}/api/lease/equipment/${id}`;

            const fetchData = async () => {
                try {
                    const result = await axios.get(url);
                    if (result.data) {
                        result.data.leaseSigningDate =
                            result.data.leaseSigningDate?.toString().substr(0, 10);
                        result.data.leaseCommencementDate =
                            result.data.leaseCommencementDate?.toString().substr(0, 10);
                        result.data.leaseExpirationDate =
                            result.data.leaseExpirationDate?.toString().substr(0, 10);
                        result.data.firstPaymentDueDate =
                            result.data.firstPaymentDueDate?.toString().substr(0, 10);
                        result.data.periodOfPerformanceStart =
                            result.data.periodOfPerformanceStart?.toString().substr(0, 10);
                        result.data.periodOfPerformanceEnd =
                            result.data.periodOfPerformanceEnd?.toString().substr(0, 10);
                    }

                    setData(result.data);
                } catch (error) {
                    console.log(error);
                }
            };

            fetchData();
        } else {
            let newData: any = {
                discountRate: 0,
                earlyTerminationPenaltyAmount: 0.0, 
                initialDirectCostAmount: 0, 
                leasePaymentComponentAmount: 0,
                leasePaymentsAfterAmount: 0,
                leasePaymentsAmount: 0,
                leasePaymentsBeforeAmount: 0,
                leasePrepaymentAmount: 0, 
                leaseTerm: 0,
                nonEarlyTerminationPenaltyAmount: 0,
                nonLeasePaymentComponentAmount: 0,
                nonRenewalPenaltyAmount: 0,
                payFrequencyTypeId: 1,
                payPeriodTypeId: 1,
                paymentPeriodFuturePayments: 0,
                paymentPeriodIfPenalty: 0,
                percentPaymentsRightToUse: 0,
                //equipment
                leasePurchaseOptionAmount: 0,
                leasedPropertyFairValue: 0,
                maxResidualAmount: 0,
                percentPolicyElection: 0,
                percentSubstantiallyAll: 0, 
                probableResidualAmount: 0,
                remainingEconomicLife: 0


            };
            setData(newData);
        }
    }, [isUpdate, id]);

    return (
        <Fragment>
            {data ? (
                <EquipmentLeaseForm
                    leaseTypeId={leaseTypeId}
                    data={data}
                    submitting={submitting}
                    saving={saving}
                    onSubmit={onSubmit}
                    onSaveDraft={onSaveDraft}
                    onCancel={onCancel}
                    purchaseRequestId={purchaseRequestId}
                />
            ) : (
                "Loading form data..."
            )}
        </Fragment>
    );
};

export default EquipmentLease;
