import React from 'react'
import { Fragment } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { ILease } from "../Interfaces/LeaseApp";
import styles from "./LeaseTable.module.css";

interface AppProps {
  leases: ILease[];
  loading: boolean;
}

const LeaseTable = ({ leases = [], loading }: AppProps) => {
  const history = useHistory();

  const handleRowClick = (e: any) => {
    const rowIndex = e.currentTarget.getAttribute("data-item");
    const selectedLease = leases[rowIndex];
    if (!selectedLease) {
      return;
    }

    const path = `/update-lease/${selectedLease.purchaseRequestId}/${selectedLease.leaseId}/${selectedLease.leaseTypeId}`;
    history.push(path);
  };

  return (
    <Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Table striped bordered hover>
          <thead className="table-header">
            <tr>
              <th>Asset Description</th>
              <th>Rental Location</th>
              <th>Status</th>
              <th>Lease Query</th>
            </tr>
          </thead>
          <tbody>
            {leases && leases.length > 0 ? (
              leases.map(function (lease, index) {
                return (
                  <tr
                    className={styles.leaseRecord}
                    key={index}
                    data-item={index}
                    onClick={handleRowClick}
                  >
                    <td>{lease.assetDescription} </td>
                    <td>{lease.rentalLocation}</td>
                    <td>{lease.isSubmitted? "Submitted" : "Draft"} </td>
                    <td>{lease.isAddedToLeaseQuery ? "Yes" : "No"}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>No Leases</td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default LeaseTable;
