import React from 'react'
import { Form, Button } from "react-bootstrap";
import { IProjectFleetFormValues } from "../Interfaces/LeaseApp";
import { useForm } from "react-hook-form";
import LeasePartial from "./LeasePartial";
import FacilityQuestions from "./FacilityQuestions";
import EquipmentPartial from "./EquipmentPartial";
import EquipmentQuestions from "./EquipmentQuestions";
import ProjectFleetPartial from "./ProjectFleetPartial";

interface IProjectFleetProps {
    data: IProjectFleetFormValues;
    submitting: boolean;
    saving: boolean;
    onSubmit: (data: IProjectFleetFormValues) => void;
    onSaveDraft: (data: IProjectFleetFormValues) => any;
    onCancel: (id: number) => any;
    purchaseRequestId: number;
    leaseTypeId: number; 
}

const ProjectFleetLeaseForm = ({
    data,
    submitting,
    saving,
    onSubmit,
    onSaveDraft,
    onCancel,
    purchaseRequestId,
    leaseTypeId,
 }: IProjectFleetProps) => {
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<IProjectFleetFormValues>({ defaultValues: data });
    const watchisEvergreen = watch("isEvergreen", data.isEvergreen);


    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="lease-general p-3 mb-2">
                <LeasePartial register={register} errors={errors} data={data} leaseTypeId={leaseTypeId} />
                <ProjectFleetPartial register={register} errors={errors} data={data} />
                <EquipmentPartial register={register} errors={errors} data={data} leaseTypeId={leaseTypeId} />
            </div>
            {!watchisEvergreen &&
                <>
                <FacilityQuestions register={register} errors={errors} data={data} />
                <EquipmentQuestions register={register} errors={errors} data={data} />
                </>
            }
            <div className="mt-2 d-flex justify-content-center justify-content-sm-end">
                <Button
                    disabled={submitting}
                    className="m-1 nw-btn btn-ghost"
                    onClick={() => onCancel(purchaseRequestId)}
                >
                    Cancel
                </Button>
                {!data.isSubmitted &&
                    <Button onClick={() => onSaveDraft(getValues())}
                        disabled={saving}
                        type="button"
                        className="m-1 btn nw-btn btn-cerulean"
                    >
                        {saving ? "Saving Draft..." : "Save As Draft"}
                    </Button>
                }
                <Button
                    disabled={submitting}
                    className="m-1 btn nw-btn btn-cerulean"
                    type="submit"
                >
                    {submitting ? "Saving..." : "Save & Close"}
                </Button>
            </div>
        </Form>
    );
};

export default ProjectFleetLeaseForm;
