import React from 'react';
import { OverlayTrigger, Popover } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";
import styles from "./LeaseTooltip.module.css";

interface AppProps {
  tooltip: { id: string; text: string };
}

const LeaseTooltip = ({ tooltip }: AppProps) => {
  const renderTooltip =
    (tooltipObj: { id: string; text: string }) => (props: any) => {
      return (
        //<Tooltip id={tooltipObj.id} {...props}>
        //    {tooltipObj.text}
        //</Tooltip>
        <Popover id={tooltipObj.id} {...props} className="popover">
          <Popover.Body>
            <span className="text-cerulean p-0" style={{ fontSize: "12" }}>
              {" "}
              {tooltipObj.text}
            </span>
          </Popover.Body>
        </Popover>
      );
    };

  return (
    <OverlayTrigger placement="bottom" overlay={renderTooltip(tooltip)}>
      <span className={styles.tooltipinfo}>
        <BsInfoCircle />
      </span>
    </OverlayTrigger>
  );
};

export default LeaseTooltip;
