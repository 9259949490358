import React from 'react'
import { Row, Col, Form } from "react-bootstrap";
import LeaseTooltip from "./LeaseTooltip";
import { ILeaseFormValues } from "../Interfaces/LeaseApp";
import { generalLeaseTooltips as tooltips } from "../Data/tooltips";

const requiredMessage: string = "This field is required";

interface ILeaseProps {
  data: ILeaseFormValues | undefined;
  register: any;
    errors: any;
    leaseTypeId: number;
}

function LeasePartial(props: ILeaseProps) {
  return (
    <>
      <Row>
        <Col sm={6}>
          <Form.Group className="mb-5" controlId="vendorLeaseNumber">
            <Form.Label>
              Vendor/Leasor No./Contract No.:{" "}
              <LeaseTooltip tooltip={tooltips.tt1} />
            </Form.Label>
            <Form.Control
              {...props.register("vendorLeaseNumber", {
                required: requiredMessage 
              }
              )}
              defaultValue={props.data?.vendorLeaseNumber}
              type="text"
              placeholder="Vendor / Leasor No. / Contract No."
              isInvalid={!!props.errors.vendorLeaseNumber}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.vendorLeaseNumber?.message}
            </Form.Control.Feedback>
            <i></i>
          </Form.Group>
        </Col>

        <Col sm={6}>
          <Form.Group className="mb-5" controlId="leaseCommencementDate">
            <Form.Label>
              Commencement Date: <LeaseTooltip tooltip={tooltips.tt3} />
            </Form.Label>
            <Form.Control
              {...props.register("leaseCommencementDate", {
                required: requiredMessage,
              })}
              defaultValue={props.data?.leaseCommencementDate} //.toISOString().substr(0, 10)}
              type="date"
              placeholder="Commencement Date"
              isInvalid={!!props.errors.leaseCommencementDate}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.leaseCommencementDate?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="mb-5" controlId="rentalLocation">
            <Form.Label>
              Rental Location/Address: <LeaseTooltip tooltip={tooltips.tt2} />
            </Form.Label>
            <Form.Control
              {...props.register("rentalLocation", {
                required: requiredMessage,
                minLength: {
                  value: 6,
                  message: "Min-length = 6",
                },
              })}
              defaultValue={props.data?.rentalLocation}
              type="text"
              placeholder="Ex. 2800 Solway Rd, Knoxville, TN 37931"
              isInvalid={!!props.errors.rentalLocation}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.rentalLocation?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col sm={6}>
          <Form.Group className="mb-5" controlId="leaseExpirationDate">
            <Form.Label>
              Expiration Date: <LeaseTooltip tooltip={tooltips.tt4} />
            </Form.Label>
            <Form.Control
              {...props.register("leaseExpirationDate", {
                required: requiredMessage,
              })}
              defaultValue={props.data?.leaseExpirationDate} //.toISOString().substr(0, 10)}
              type="date"
              placeholder="Commencement Date"
              isInvalid={!!props.errors.leaseExpirationDate}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.leaseExpirationDate?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="mb-5" controlId="isAddedToLeaseQuery">
            <Form.Label>Is this Lease record in LeaseQuery?</Form.Label>
            <Form.Check
              id="isAddedToLeaseQuery"
              name="isAddedToLeaseQuery"
              type="switch"
              label={props.data?.isAddedToLeaseQuery ? "Yes" : "No"}
              onClick={(e) =>
                ((e.target as HTMLFormElement).labels[1].innerHTML = (
                  e.target as HTMLFormElement
                ).checked
                  ? "Yes"
                  : "No")
              }
              aria-label="isAddedToLeaseQuery"
              defaultChecked={props.data?.isAddedToLeaseQuery}
              {...props.register("isAddedToLeaseQuery")}
              isInvalid={!!props.errors.isAddedToLeaseQuery}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.isAddedToLeaseQuery?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-5" controlId="leaseSigningDate">
            <Form.Label>Lease Signing Date:</Form.Label>
            <Form.Control
              {...props.register("leaseSigningDate", {
                required: requiredMessage,
              })}
              defaultValue={props.data?.leaseSigningDate}
              type="date"
              //{/*placeholder="Signing Date"*/}
              isInvalid={!!props.errors.leaseSigningDate}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.leaseSigningDate?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Form.Group className="mb-5" controlId="assetDescription">
            <Form.Label>Description of Leased Asset/Item:</Form.Label>
            <Form.Control
              {...props.register("assetDescription", {
                required: requiredMessage,
                minLength: {
                  value: 6,
                  message: "Min-length = 6",
                },
              })}
              as="textarea"
              defaultValue={props.data?.assetDescription}
              type="text"
              placeholder="Asset Description"
              isInvalid={!!props.errors.assetDescription}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.assetDescription?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export default LeasePartial;
