import React from 'react';
import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import axios from "axios";
import { IPurchaseRequest } from "../Interfaces/LeaseApp";
import { LeaseType } from "../Enums/LeastType";
import LeaseTable from "../Components/LeaseTable";

function Details() {
  const [data, setData] = useState<IPurchaseRequest>();
  const [loading, setLoading] = useState(true);
  const params = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    const url = window.location.origin + "/api/purchaserequest/" + params.id;

    const fetchData = async () => {
      try {
        const result = await axios.get(url);

        setData(result.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [params.id]);

  const handleAddLease = (eventKey: any) => {
    const leasetypeid = eventKey;
    const path = `/create-lease/${params.id}/${leasetypeid}`;
    history.push(path);
  };

  return (
    <div>
      {data ? (
        <Container>
          <Row className="p-0">
            <Col>
              <h5 className="nwg-heading">Purchase Request Details</h5>
            </Col>
          </Row>
          <Row className="p-0 mb-3">
            <Col>
              <Row className="p-0">
                <label className="col-12 col-sm-6 col-form-label">
                  Purchase Request Number:
                </label>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    id="purchaseRequestNumber"
                    value={data?.purchaseRequestNumber}
                    readOnly
                  />
                </div>
              </Row>

              <Row className="p-0">
                <label className="col-12 col-sm-6 col-form-label">
                  Purchase Order Number:
                </label>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    id="purchaseOrderNumber"
                    value={data?.purchaseOrderNumber}
                    readOnly
                  />
                </div>
              </Row>
            </Col>
            <Col>
              <Row className="p-0">
                <label className="col-12 col-sm-6 col-form-label">
                  Project #:
                </label>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    id="projectNumber"
                    value={data?.projectNumber}
                    readOnly
                  />
                </div>
              </Row>

              <Row className="p-0">
                <label className="col-12 col-sm-6 col-form-label">
                  Lease Type:
                </label>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    id="leaseType"
                    value={data?.leaseType ? LeaseType[data.leaseType] : "N/A"}
                    readOnly
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="py-3">
            <h5 className="nwg-heading">Vendor Information</h5>
            <Col sm={12} md={6}>
              <Row className="p-0">
                <label className="col-12 col-sm-6 col-form-label">
                  Vendor/Lease Name:
                </label>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    id="vendorLeaseName"
                    value={data?.vendorName}
                    readOnly
                  />
                </div>
              </Row>

              <Row className="p-0">
                <label className="col-12 col-sm-6 col-form-label">
                  Vendor/Lease Address:
                </label>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    id="vendorLeaseAddress"
                    value={data?.vendorAddress}
                    readOnly
                  />
                </div>
              </Row>
            </Col>
            <Col sm={12} md={6}>
              <Row className="p-0">
                <label className="col-12 col-sm-6 col-form-label">
                  Vendor/Lease Phone Number:
                </label>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    id="vendorLeasePhone"
                    value={data?.vendorPhone}
                    readOnly
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="p-0  mb-1">
            <Col>
              {data?.leaseType && (
                <Link
                  className="btn nw-btn btn-block float-end btn-cerulean text-white"
                  to={`/create-lease/${params.id}/${data?.leaseType}`}
                >
                  Add New {LeaseType[data?.leaseType]} Lease
                </Link>
              )}
            </Col>
          </Row>
          <Row className="p-0">
            <h5 className="nwg-heading">Lease Status</h5>
            <LeaseTable leases={data.leases} loading={loading} />
          </Row>
        </Container>
      ) : (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
}

export default Details;
