import React from 'react'
import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react/";
import "./Header.css";
import { UserClaims } from "@okta/okta-auth-js";
import { useHistory } from "react-router";

const Header = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims>();
  const history = useHistory();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(undefined);
    } else {
      oktaAuth
        .getUser()
        .then((info: UserClaims) => {
          setUserInfo(info);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]);

  const handleOnClick = (e: any) => {
    e.preventDefault();
    history.push("/");
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-toggleable-sm bg-whisper border-whisper mb-3">
      <div className="container">
        <a className="navbar-brand" onClick={handleOnClick} href="/">
          <img src="/images/lease-logo.png" alt="Lease Logo"></img>
        </a>

        <ul className="navbar-nav mr-auto"></ul>
        <span className="navbar-text">
          {!userInfo ? "Loading User Info..." : `Hello, ${userInfo.name}`}
        </span>
      </div>
    </nav>
  );
};

export default Header;
