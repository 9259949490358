import React from 'react';
import { useState } from "react";
import {
    Container,
    Row,
    Toast,
    ToastHeader,
    ToastBody,
    ToastContainer,
} from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";

import ProjectFleetLease from "../Components/ProjectFleetLease";
import FacilityLease from "../Components/FacilityLease";
import { IFacilityFormValues, IProjectFleetFormValues } from "../Interfaces/LeaseApp";
import { LeaseType } from "../Enums/LeastType";
import EquipmentLease from "../Components/EquipmentLease";

const UpdateLease = () => {
    const { leasetypeid, leaseid, prId } =
        useParams<{ leasetypeid: string; leaseid: string; prId: string }>();

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const [showToast, setShowToast] = useState<boolean>(false);
    const [responseError, setResponseError] = useState<string>();
    const history = useHistory();

    const purchaseRequestId: number = parseInt(prId);

    const leaseId: number = parseInt(leaseid);
    const leaseTypeId: number = parseInt(leasetypeid);
    let apiLeaseUri: string = "";
    let leaseType: string = "";

    let leaseform = <p>Invalid lease form</p>;

    const redirect = (prId: number | undefined) => {
        const path = `/detail/${prId}`;
        history.push(path);
    };

    const onSaveDraft = (formData: IFacilityFormValues | IProjectFleetFormValues) => {
        setSaving(true);
        const url = `${window.location.origin}/api/lease/${apiLeaseUri}`;
        formData.leaseId = leaseId; // leaseid required for update 
        formData.isSubmitted = false;
        formData.leaseTypeId = leaseTypeId;

        //Reset question values to default if isEvergreen true
        ResetIsEvergreenQuestions(formData);

        axios
            .put(url, formData)
            .then((response: any) => {
                setSaving(false);
                redirect(purchaseRequestId);
            })
            .catch((error: any) => {
                let errMessage = `Error occurred while creating ${LeaseType[leaseTypeId]} lease for PurchaseRequestId: ${purchaseRequestId}`;
                if (error.response.status === 400 && error.response.data) {
                    errMessage = `Submit error: ${error.response.data}`;
                }
                setResponseError(errMessage);
                setShowToast(true);
                setSaving(false);
            });

    }

    function ResetIsEvergreenQuestions(formData: IFacilityFormValues | IProjectFleetFormValues) {
        if ('isEvergreen' in formData && formData.isEvergreen == true) {
            //Q11
            formData.discountRate = 0;
            //Q10
            formData.earlyTerminationPenaltyAmount = 0.0;
            //Q2
            formData.firstPaymentDueDate = undefined;
            //Q13
            formData.initialDirectCostAmount = 0;
            //Q20
            formData.isAssetAlternativeUse = false;
            //Q26
            formData.isCommencementNearEndOfLife = false;
            //Q19
            formData.isLeaseOwnershipTransfer = false;
            //Q21
            formData.isLeasePurchaseOption = false;
            //Q5
            formData.isPresumedLeaseRenewal = false;
            //Q7
            formData.isPresumedNotEarlyTermination = false;
            //Q23
            formData.isResidualValueGuarantee = false;
            //Q18a
            formData.leasePaymentComponentAmount = 0;
            //Q15
            formData.leasePaymentsAfterAmount = 0;
            //Q18
            formData.leasePaymentsAmount = 0;
            //Q14
            formData.leasePaymentsBeforeAmount = 0;
            //Q12
            formData.leasePrepaymentAmount = 0;
            //Q4
            formData.leaseTerm = 0;
            //Q24
            formData.maxResidualAmount = 0;
            //Q8
            formData.nonEarlyTerminationPenaltyAmount = 0;
            //Q18b
            formData.nonLeasePaymentComponentAmount = 0;
            //Q6
            formData.nonRenewalPenaltyAmount = 0;
            //Q1
            formData.payFrequencyTypeId = 1;
            //Q3
            formData.payPeriodTypeId = 1;
            //Q16
            formData.paymentPeriodFuturePayments = 0;
            //Q9
            formData.paymentPeriodIfPenalty = 0;
            //Q17
            formData.percentPaymentsRightToUse = 0;
            //Q22
            formData.leasePurchaseOptionAmount = 0;
            //Q29
            formData.leasedPropertyFairValue = 0;
            //Q28
            formData.percentPolicyElection = 0;
            //Q30
            formData.percentSubstantiallyAll = 0;
            //Q25
            formData.probableResidualAmount = 0;
            //Q27
            formData.remainingEconomicLife = 0;
        }
    }

    const onSubmit = (formData: IFacilityFormValues | IProjectFleetFormValues) => {
        setSubmitting(true);
        const url = `${window.location.origin}/api/lease/${apiLeaseUri}`;
        formData.leaseId = leaseId; // leaseid required for update
        formData.isSubmitted = true;
        formData.leaseTypeId = leaseTypeId;

        //Reset question values to default if isEvergreen true
        ResetIsEvergreenQuestions(formData);
         
        axios
            .put(url, formData)
            .then((response: any) => {
                setSubmitting(false);
                redirect(formData?.purchaseRequestId);
            })
            .catch((error: any) => {
                let errMessage = `Error occurred while updating ${LeaseType[leaseTypeId]} leaseId: ${leaseId}`;
                if (error.response.status === 400 && error.response.data) {
                    errMessage = `Submit error: ${error.response.data}`;
                }
                setResponseError(errMessage);
                setShowToast(true);
                setSubmitting(false);
            });
    };

    switch (leaseTypeId) {
        case 1:
            apiLeaseUri = "facility";
            leaseType = "Facility";
            leaseform = (
                <FacilityLease
                    leaseTypeId={leaseTypeId}
                    id={leaseId} 
                    isUpdate={true}
                    submitting={submitting}
                    saving={saving}
                    onSubmit={onSubmit}
                    onSaveDraft={onSaveDraft}
                    onCancel={redirect}
                    purchaseRequestId={purchaseRequestId}
                />
            );
            break;
        case 2:
            apiLeaseUri = "equipment";
            leaseType = "Office Equipment";
            leaseform = (
                <EquipmentLease
                    leaseTypeId={leaseTypeId}
                    id={leaseId}
                    isUpdate={true}
                    submitting={submitting}
                    saving={saving}
                    onSubmit={onSubmit}
                    onSaveDraft={onSaveDraft}
                    onCancel={redirect}
                    purchaseRequestId={purchaseRequestId}
                />
            );
            break;
        case 3:
            apiLeaseUri = "projectfleet";
            leaseType = "Project";
            leaseform = (
                <ProjectFleetLease
                    leaseTypeId={leaseTypeId}
                    id={leaseId}
                    isUpdate={true}
                    submitting={submitting}
                    saving={saving}
                    onSubmit={onSubmit}
                    onSaveDraft={onSaveDraft}
                    onCancel={redirect}
                    purchaseRequestId={purchaseRequestId}
                />
            );
            break;
        case 4:
            apiLeaseUri = "projectfleet";
            leaseType = "Fleet";
            leaseform = (
                <ProjectFleetLease
                    leaseTypeId={leaseTypeId}
                    id={leaseId}
                    isUpdate={true}
                    submitting={submitting}
                    saving={saving}
                    onSubmit={onSubmit}
                    onSaveDraft={onSaveDraft}
                    onCancel={redirect}
                    purchaseRequestId={purchaseRequestId}
                />
            );
            break;
    }

    return (
        <Container>
            <Row className="mb-5">
                <ToastContainer className="p-3">
                    <Toast
                        show={showToast}
                        bg="danger"
                        onClose={() => setShowToast(false)}
                    >
                        <ToastHeader>
                            <strong className="me-auto">Submit Error</strong>
                        </ToastHeader>
                        <ToastBody>{responseError}</ToastBody>
                    </Toast>
                </ToastContainer>
                <Row className="mb-4">
                    <h5 className="nwg-heading">{leaseType} Lease Form </h5>
                </Row>
                {leaseform}
            </Row>
        </Container>
    );
};

export default UpdateLease;
