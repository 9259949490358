import React from 'react'
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { IEquipmentFormValues } from "../Interfaces/LeaseApp";
import { equipmentLeaseTooltips as tooltips } from "../Data/tooltips";
import LeaseTooltip from "./LeaseTooltip";

const requiredMessage: string = "This field is required";

interface IEquipmentQuestionsProps {
  data: IEquipmentFormValues | undefined;
  register: any;
  errors: any;
}

function EquipmentQuestions(props: IEquipmentQuestionsProps) {
  return (
    <div id="flf">
      <Row>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="isLeaseOwnershipTransfer">
            <Form.Label>
              19. Does ownership of the asset transfer to the lessee at the end
              of the lease term? <LeaseTooltip tooltip={tooltips.tt19} />
            </Form.Label>
            <Form.Check
              type="switch"
              label={props.data?.isLeaseOwnershipTransfer ? "Yes" : "No"}
              onClick={(e) =>
                ((e.target as HTMLFormElement).labels[1].innerHTML = (
                  e.target as HTMLFormElement
                ).checked
                  ? "Yes"
                  : "No")
              }
              aria-label="isLeaseOwnershipTransfer"
              defaultChecked={props.data?.isLeaseOwnershipTransfer}
              {...props.register("isLeaseOwnershipTransfer", {})}
              isInvalid={!!props.errors.isLeaseOwnershipTransfer}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.isLeaseOwnershipTransfer?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="isAssetAlternativeUse">
            <Form.Label>
              20. Does the asset have an alternative use to the lessor at the
              end of the lease term?
            </Form.Label>
            <Form.Check
              type="switch"
              label={props.data?.isAssetAlternativeUse ? "Yes" : "No"}
              onClick={(e) =>
                ((e.target as HTMLFormElement).labels[1].innerHTML = (
                  e.target as HTMLFormElement
                ).checked
                  ? "Yes"
                  : "No")
              }
              aria-label="isAssetAlternativeUse"
              defaultChecked={props.data?.isAssetAlternativeUse}
              {...props.register("isAssetAlternativeUse", {})}
              isInvalid={!!props.errors.isAssetAlternativeUse}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.isAssetAlternativeUse?.message}
            </Form.Control.Feedback>
            <i></i>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="isLeasePurchaseOption">
            <Form.Label>
              21. Does the lease contain a purchase option that the lessee is
              reasonably certain to exercise?{" "}
              <LeaseTooltip tooltip={tooltips.tt21} />
            </Form.Label>
            <Form.Check
              type="switch"
              label={props.data?.isLeasePurchaseOption ? "Yes" : "No"}
              onClick={(e) =>
                ((e.target as HTMLFormElement).labels[1].innerHTML = (
                  e.target as HTMLFormElement
                ).checked
                  ? "Yes"
                  : "No")
              }
              aria-label="isLeasePurchaseOption"
              defaultChecked={props.data?.isLeasePurchaseOption}
              {...props.register("isLeasePurchaseOption", {})}
              isInvalid={!!props.errors.isLeasePurchaseOption}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.isLeasePurchaseOption?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="leasePurchaseOptionAmount">
            <Form.Label>
              22. If yes, what is the price of the purchase option that the
              Lessee is reasonably certain to exercise?{" "}
              <LeaseTooltip tooltip={tooltips.tt22} />
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                defaultValue={props.data?.leasePurchaseOptionAmount}
                {...props.register("leasePurchaseOptionAmount", {})}
                type="number"
                step="0.01"
                min="0.00"
                isInvalid={!!props.errors.leasePurchaseOptionAmount}
              />
              <Form.Control.Feedback type="invalid">
                {props.errors.leasePurchaseOptionAmount?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="isResidualValueGuarantee">
            <Form.Label>
              23. Does the lease contain a residual value guarantee?{" "}
              <LeaseTooltip tooltip={tooltips.tt23} />
            </Form.Label>
            <Form.Check
              type="switch"
              label={props.data?.isResidualValueGuarantee ? "Yes" : "No"}
              onClick={(e) =>
                ((e.target as HTMLFormElement).labels[1].innerHTML = (
                  e.target as HTMLFormElement
                ).checked
                  ? "Yes"
                  : "No")
              }
              aria-label="isResidualValueGuarantee"
              defaultChecked={props.data?.isResidualValueGuarantee}
              {...props.register("isResidualValueGuarantee", {})}
              isInvalid={!!props.errors.isResidualValueGuarantee}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.isResidualValueGuarantee?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="maxResidualAmount">
            <Form.Label>
              24. If yes, what is the maximum amount that the lessee could owe
              under the residual value guarantee?{" "}
              <LeaseTooltip tooltip={tooltips.tt24} />
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                defaultValue={props.data?.maxResidualAmount}
                {...props.register("maxResidualAmount", {})}
                type="number"
                step="0.01"
                min="0.00"
                isInvalid={!!props.errors.maxResidualAmount}
              />
              <Form.Control.Feedback type="invalid">
                {props.errors.maxResidualAmount?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="probableResidualAmount">
            <Form.Label>
              25. What is the <i>probable</i> amount of the residual value
              guarantee that the lessee will pay at the end of the lease term?{" "}
              <LeaseTooltip tooltip={tooltips.tt25} />
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                defaultValue={props.data?.probableResidualAmount}
                {...props.register("probableResidualAmount", {})}
                type="number"
                step="0.01"
                min="0.00"
                isInvalid={!!props.errors.probableResidualAmount}
              />
              <Form.Control.Feedback type="invalid">
                {props.errors.probableResidualAmount?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="isCommencementNearEndOfLife">
            <Form.Label>
              26. Does the commencement date of the lease fall at or near the
              end of the economic life of the underlying asset?
            </Form.Label>
            <Form.Check
              type="switch"
              label={props.data?.isCommencementNearEndOfLife ? "Yes" : "No"}
              onClick={(e) =>
                ((e.target as HTMLFormElement).labels[1].innerHTML = (
                  e.target as HTMLFormElement
                ).checked
                  ? "Yes"
                  : "No")
              }
              aria-label="isCommencementNearEndOfLife"
              defaultChecked={props.data?.isCommencementNearEndOfLife}
              {...props.register("isCommencementNearEndOfLife", {})}
              isInvalid={!!props.errors.isCommencementNearEndOfLife}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.isCommencementNearEndOfLife?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="remainingEconomicLife">
            <Form.Label>
              27. What is the leased asset's remaining economic life at lease
              commencement? (In Months) <LeaseTooltip tooltip={tooltips.tt27} />
            </Form.Label>
            <Form.Control
              defaultValue={props.data?.remainingEconomicLife}
              {...props.register("remainingEconomicLife", {})}
              type="number"
              step="1"
              min="0"
              isInvalid={!!props.errors.remainingEconomicLife}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.remainingEconomicLife?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="percentPolicyElection">
            <Form.Label>
              28. What is the lessee's policy election as to what percent
              represents a "major part" of the asset's remaining economic life?
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>%</InputGroup.Text>
              <Form.Control
                defaultValue={props.data?.percentPolicyElection}
                {...props.register("percentPolicyElection", {
                  required: requiredMessage,
                  min: { value: 0, message: "Must be greater than 0" },
                  max: {
                    value: 100,
                    message: "Must not be greater than 100",
                  },
                })}
                type="number"
                // min="0" max="100"
                isInvalid={!!props.errors.percentPolicyElection}
              />
              <Form.Control.Feedback type="invalid">
                {props.errors.percentPolicyElection?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="leasedPropertyFairValue">
            <Form.Label>
              29. What is the fair value of the leased property to the lessor at
              lease commencement?
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                defaultValue={props.data?.leasedPropertyFairValue}
                {...props.register("leasedPropertyFairValue", {
                  required: requiredMessage,
                })}
                type="number"
                step="0.01"
                min="0.00"
                isInvalid={!!props.errors.leasedPropertyFairValue}
              />
              <Form.Control.Feedback type="invalid">
                {props.errors.leasedPropertyFairValue?.message}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className="mb-5" controlId="percentSubstantiallyAll">
            <Form.Label>
              30. What is the lessee's policy election as to what percent
              constitutes "substantially all" of the asset's fair value?{" "}
              <LeaseTooltip tooltip={tooltips.tt30} />
            </Form.Label>
            <Form.Control
              {...props.register("percentSubstantiallyAll", {
                required: requiredMessage,
                minLength: {
                  value: 1,
                  message: "Min-length = 1",
                },
              })}
              defaultValue={props.data?.percentSubstantiallyAll}
              type="text"
              isInvalid={!!props.errors.percentSubstantiallyAll}
            />
            <Form.Control.Feedback type="invalid">
              {props.errors.percentSubstantiallyAll?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}

export default EquipmentQuestions;
