import React, { useState } from "react";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";

interface AppProps {
    handleSearchSubmit: (searchType: string, searchValue: string) => void;
    searchType: string;
    searchValue: string;
}

const PurchaseRequestSearch = ({
    handleSearchSubmit,
    searchType,
    searchValue,
}: AppProps) => {
    const [internalSearchType, setInternalSearchType] = useState(searchType);
    const [internalSearchValue, setInternalSearchValue] = useState(searchValue);

    const handleSearchTypeChange = (evt: any) => {
        evt.preventDefault();
        setInternalSearchType(evt.target.value);
        setInternalSearchValue("");
    };

    const handleSearchValueChange = (evt: any) => {
        evt.preventDefault();
        setInternalSearchValue(evt.target.value);
    };

    const handleFormSubmit = (evt: any) => {
        evt.preventDefault();
        handleSearchSubmit(internalSearchType, internalSearchValue);
    };

    return (
        <Form onSubmit={handleFormSubmit}>
            <Row className="p-0 mb-2">
                <Col sm={6}>
                </Col>

                <Col sm={3}>
                
                <Form.Group  >
                    {internalSearchType === "4" ? (
                        <InputGroup>
                            <Form.Select
                                style={{ borderRadius: '0px'}}
                                defaultValue="None"
                                onChange={handleSearchValueChange}
                            >
                                <option value="None" disabled>
                                    Choose...
                                </option>
                                <option value="Facility">Facility</option>
                                <option value="Equipment">Equipment</option>
                                <option value="Project">Project</option>
                                <option value="Fleet">Fleet</option>
                            </Form.Select>

                            <Button
                                style={{ minWidth: '0px', borderRadius: '0px', backgroundColor:'#eee' }}
                                type="submit" 
                                id="button-addon2"
                                disabled={internalSearchValue.length === 0}
                            >
                                    <BsSearch style={{ color: '#333' }}  />
                            </Button>


                        </InputGroup>
                    ) : (
                        <InputGroup>
                            <Form.Control
                                    placeholder="Search Input"
                                    aria-describedby="basic-addon2"
                                    value={internalSearchValue}
                                    onChange={handleSearchValueChange}
                                    disabled={["0", "5"].includes(internalSearchType)}
                                    style={{ borderRadius: '0px'}}
                            />

                            <Button
                                style={{ minWidth: '0px', borderRadius: '0px', backgroundColor: '#eee'}}
                                type="submit" 
                                id="button-addon2"
                                disabled={
                                    !["0", "5"].includes(internalSearchType) &&
                                    internalSearchValue.length === 0
                                }
                            >
                                        <BsSearch style={{ color: '#333' }} />
                            </Button>

                        </InputGroup>
                    )}
                </Form.Group>
                </Col>

                <Col sm={3}>

                <Form.Group   controlId="searchType">
                    <Form.Select
                        style={{ borderRadius: '0px', backgroundColor: '#eee'}}
                        value={internalSearchType}
                        onChange={handleSearchTypeChange}
                         
                    >
                        <option style={{ backgroundColor: '#fff' }} value="0">All</option>
                        <option style={{ backgroundColor: '#fff' }} value="1">Purchase Request Number</option>
                        <option style={{ backgroundColor: '#fff' }} value="2">Purchase Order Number</option>
                        <option style={{ backgroundColor: '#fff' }} value="3">Project Number</option>
                        <option style={{ backgroundColor: '#fff' }} value="4">Lease Type</option>
                        <option style={{ backgroundColor: '#fff' }} value="5">Evergreen Lease</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            </Row>
        </Form>
    );
};

export default PurchaseRequestSearch;
