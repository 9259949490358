import React from 'react';
import { Fragment } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LeaseType } from "../Enums/LeastType";
import LoadingSpinner from "./LoadingSpinner";
import { IPurchaseRequest } from "../Interfaces/LeaseApp";
import styles from "./PurchaseRequestTable.module.css";

interface AppProps {
  data: IPurchaseRequest[];
  loading: boolean;
}

const PurchaseRequestTable = ({ data, loading }: AppProps) => {
  const history = useHistory();
  let tableBody = null;

  const handleRowClick = (e: any) => {
    const rowIndex = e.currentTarget.getAttribute("data-item");
    const selectedPr = data[rowIndex];
    if (!selectedPr) {
      return;
    }

    const path = `/detail/${selectedPr.purchaseRequestId}`;
    history.push(path);
  };

  if (data && data.length > 0) {
    tableBody = data.map((element: any, index: number) => (
      <tr
        className={styles.prRecord}
        key={element.purchaseRequestNumber + element.purchaseRequestId}
        data-item={index}
        onClick={handleRowClick}
      >
        <td>{element.purchaseRequestNumber}</td>
        <td>{element.purchaseOrderNumber}</td>
        <td>{element.projectNumber}</td>
        <td>{element.leaseType ? LeaseType[element.leaseType] : "N/A"}</td>
        <td>{new Date(element.createdDateTime).toLocaleDateString()}</td>
        <td>{element.modifiedBy}</td>
        <td>{element.leases <= 0 ? "Open" : "Submitted"}</td>
      </tr>
    ));
  } else {
    tableBody = (
      <tr key="1">
        <td colSpan={7}>No records found</td>
      </tr>
    );
  }

  return (
    <Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Table bordered hover striped>
          <thead className="table-header text-center align-top">
            <tr>
              <th>Purchase Request Number</th>
              <th>Purchase Order Number</th>
              <th>Project Number</th>
              <th>Lease Type</th>
              <th>Created Date</th>
              <th>Submitted By</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default PurchaseRequestTable;
