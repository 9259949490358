import React from 'react'
import { Pagination } from "react-bootstrap";
import PropTypes from "prop-types";
import "./LeasePagination.module.css";

interface AppProps {
  currentPage: number;
  pageLimit: number;
  totalRecords: number;
  pageNeighbours: number;
  onPageChange: (currentPage: number) => void;
}

const LEFT_PAGE: string = "LEFT";
const RIGHT_PAGE: string = "RIGHT";

const range = (from: number, to: number, step: number = 1) => {
  let i = from;
  const range: Array<number | string> = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }
  return range;
};

const LeasePagination = ({
  currentPage = 1,
  pageLimit = 10,
  totalRecords = 0,
  pageNeighbours = 1,
  onPageChange,
}: AppProps) => {
  let totalPages = Math.ceil(totalRecords / pageLimit);

  const buildPageNumbers = (): Array<number | string> => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const gotoPage = (page: number) => {
    const currentPage = Math.max(0, Math.min(page, totalPages));
    onPageChange(currentPage);
  };

  const handleClick = (page: number) => (evt: any) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt: any) => {
    evt.preventDefault();
    let newPage = currentPage - pageNeighbours * 2 - 1;
    gotoPage(newPage);
  };

  const handleMoveRight = (evt: any) => {
    evt.preventDefault();
    let newPage = currentPage + pageNeighbours * 2 + 1;
    gotoPage(newPage);
  };

  if (!totalRecords || !totalPages) {
    return null;
  }

  const pages: Array<number | string> = buildPageNumbers();

  return (
    <div className="float-start">
      <Pagination className="p-2" size="sm">
        {pages.map((page: number | string, index: number) => {
          if (page === LEFT_PAGE)
            return <Pagination.Prev key={index} onClick={handleMoveLeft} />;

          if (page === RIGHT_PAGE)
            return <Pagination.Next key={index} onClick={handleMoveRight} />;

          return (
            <Pagination.Item
              active={page === currentPage}
              key={index}
              onClick={handleClick(page as number)}
            >
              {page}
            </Pagination.Item>
          );
        })}
      </Pagination>
    </div>
  );
};

LeasePagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
};

export default LeasePagination;
