import React from 'react'
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { IFacilityFormValues } from "../Interfaces/LeaseApp";
import LeaseTooltip from "./LeaseTooltip";
import { facilityLeaseTooltips as tooltips } from "../Data/tooltips";

const requiredMessage: string = "This field is required";

interface IFacilityQuestionsProps {
    data: IFacilityFormValues ;
    register: any;
    errors: any;
}

function FacilityQuestions(props: IFacilityQuestionsProps) {
    return (
        <div id="flf">
            <Row>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="payFrequencyTypeId">
                        <Form.Label>1. What is the payment frequency?</Form.Label>
                        <Form.Select
                            aria-label="Select"
                            defaultValue={props.data?.payFrequencyTypeId}
                            {...props.register("payFrequencyTypeId", {
                                required: requiredMessage,
                                min:2
                            })}
                            isInvalid={!!props.errors.payFrequencyTypeId}
                        >
                            <option value="1">
                                -- Select --
                            </option>
                            <option value="2">(A)nnual</option>
                            <option value="3">(S)emi-Annual</option>
                            <option value="4">(Q)uarterly</option>
                            <option value="5">(M)onthly</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {props.errors.payFrequencyTypeId?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="firstPaymentDueDate">
                        <Form.Label>
                            2. What is the first date under the lease that a payment is due?
                            (For purposes of this question, ignore rent holidays.){" "}
                            <LeaseTooltip tooltip={tooltips.tt2} />
                        </Form.Label>
                        <Form.Control
                            {...props.register("firstPaymentDueDate", {
                                required: requiredMessage,
                            })}
                            defaultValue={props.data?.firstPaymentDueDate}
                            type="date"
                            placeholder="Period of Performance"
                            isInvalid={!!props.errors.firstPaymentDueDate}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.firstPaymentDueDate?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="payPeriodTypeId">
                        <Form.Label>3. When are lease payments made?</Form.Label>
                        <Form.Select
                            aria-label="Select"
                            defaultValue={props.data?.payPeriodTypeId}
                            {...props.register("payPeriodTypeId", {
                                required: requiredMessage,
                                min: 2
                            })}
                            isInvalid={!!props.errors.payPeriodTypeId}
                        >
                            <option value="1">
                                -- Select --
                            </option>
                            <option value="2">(B)eginning of the period in advance</option>
                            <option value="3">(E)nd of the period of arrears</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {props.errors.payPeriodTypeId?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="leaseTerm">
                        <Form.Label>
                            4. What is the lease term in months?{" "}
                            <LeaseTooltip tooltip={tooltips.tt4} />
                        </Form.Label>
                        <Form.Control
                            defaultValue={props.data?.leaseTerm}
                            {...props.register("leaseTerm", {
                                required: requiredMessage,
                            })}
                            type="number"
                            min="0"
                            isInvalid={!!props.errors.leaseTerm}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.leaseTerm?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="isPresumedLeaseRenewal">
                        <Form.Label>
                            5. Does the lease term input in Question 4 presume that the lessee
                            will exercise an option to extend/renew the lease?{" "}
                            <LeaseTooltip tooltip={tooltips.tt5} />
                        </Form.Label>
                        <Form.Check
                            id="isPresumedLeaseRenewal"
                            name="isPresumedLeaseRenewal"
                            type="switch"
                            label={props.data?.isPresumedLeaseRenewal ? "Yes" : "No"}
                            onClick={(e) =>
                            ((e.target as HTMLFormElement).labels[1].innerHTML = (
                                e.target as HTMLFormElement
                            ).checked
                                ? "Yes"
                                : "No")
                            }
                            aria-label="isPresumedLeaseRenewal"
                            defaultChecked={props.data?.isPresumedLeaseRenewal}
                            {...props.register("isPresumedLeaseRenewal", {})}
                            isInvalid={!!props.errors.isPresumedLeaseRenewal}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.isPresumedLeaseRenewal?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="nonRenewalPenaltyAmount">
                        <Form.Label>
                            6. If no, what is the penalty for failure to extend/renew the
                            lease (if any)? <LeaseTooltip tooltip={tooltips.tt6} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.nonRenewalPenaltyAmount}
                                {...props.register("nonRenewalPenaltyAmount", {})}
                                type="number"
                                step="0.01"
                                min="0.00"
                                isInvalid={!!props.errors.nonRenewalPenaltyAmount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.nonRenewalPenaltyAmount?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group
                        className="mb-5"
                        controlId="isPresumedNotEarlyTermination"
                    >
                        <Form.Label>
                            7. Does the lease term input in Question 4 presume that the lessee
                            would not exercise an option to terminate the lease?{" "}
                        </Form.Label>
                        <Form.Check
                            type="switch"
                            label={props.data?.isPresumedNotEarlyTermination ? "Yes" : "No"}
                            onClick={(e) =>
                            ((e.target as HTMLFormElement).labels[1].innerHTML = (
                                e.target as HTMLFormElement
                            ).checked
                                ? "Yes"
                                : "No")
                            }
                            aria-label="isPresumedNotEarlyTermination"
                            defaultChecked={props.data?.isPresumedNotEarlyTermination}
                            {...props.register("isPresumedNotEarlyTermination", {})}
                            isInvalid={!!props.errors.isPresumedNotEarlyTermination}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.isPresumedNotEarlyTermination?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group
                        className="mb-5"
                        controlId="nonEarlyTerminationPenaltyAmount"
                    >
                        <Form.Label>
                            8. If yes, what (if any) would be the penalty for early termination of the lease?
                            <LeaseTooltip tooltip={tooltips.tt8} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.nonEarlyTerminationPenaltyAmount}
                                {...props.register("nonEarlyTerminationPenaltyAmount", {})}
                                type="number"
                                step="0.01"
                                min="0.00"
                                isInvalid={!!props.errors.nonEarlyTerminationPenaltyAmount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.nonEarlyTerminationPenaltyAmount?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="paymentPeriodIfPenalty">
                        <Form.Label>
                            9. In what payment period do you expect the penalty in Question 8
                            to be paid? <LeaseTooltip tooltip={tooltips.tt9} />
                        </Form.Label>
                        <Form.Control
                            defaultValue={props.data?.paymentPeriodIfPenalty}
                            {...props.register("paymentPeriodIfPenalty", {})}
                            type="number"
                            step="1"
                            min="0"
                            isInvalid={!!props.errors.paymentPeriodIfPenalty}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.paymentPeriodIfPenalty?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group
                        className="mb-5"
                        controlId="earlyTerminationPenaltyAmount"
                    >
                        <Form.Label>
                            10. If the answer to Question 17 is no, what is the amount of the
                            penalty for exercisng an early termination option (if any)?{" "}
                            <LeaseTooltip tooltip={tooltips.tt10} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.earlyTerminationPenaltyAmount}
                                {...props.register("earlyTerminationPenaltyAmount", {})}
                                type="number"
                                step="0.01"
                                min="0.00"
                                isInvalid={!!props.errors.earlyTerminationPenaltyAmount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.earlyTerminationPenaltyAmount?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="discountRate">
                        <Form.Label>
                            11. What is the appropriate discount rate? If readily
                            determinable, use the rate implicit in the lease. Otherwise, use
                            the lessee's incremental borrowing rate.{" "}
                            <LeaseTooltip tooltip={tooltips.tt11} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>%</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.discountRate}
                                {...props.register("discountRate", {
                                    required: requiredMessage,
                                    min: { value: 0, message: "Must be greater than 0" },
                                    max: {
                                        value: 100,
                                        message: "Must not be greater than 100",
                                    },
                                })}
                                type="number"
                                // min="0" max="100"
                                isInvalid={!!props.errors.discountRate}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.discountRate?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="leasePrepaymentAmount">
                        <Form.Label>
                            12. What is the amount of any lease prepayments made before lease
                            commencement (if any)? <LeaseTooltip tooltip={tooltips.tt12} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.leasePrepaymentAmount}
                                {...props.register("leasePrepaymentAmount", {
                                    required: requiredMessage,
                                })}
                                type="number"
                                step="0.01"
                                min="0.00"
                                isInvalid={!!props.errors.leasePrepaymentAmount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.leasePrepaymentAmount?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="initialDirectCostAmount">
                        <Form.Label>
                            13. What are initial direct costs (if any)?{" "}
                            <LeaseTooltip tooltip={tooltips.tt13} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.initialDirectCostAmount}
                                {...props.register("initialDirectCostAmount", {
                                    required: requiredMessage,
                                })}
                                type="number"
                                step="0.01"
                                min="0.00"
                                isInvalid={!!props.errors.initialDirectCostAmount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.initialDirectCostAmount?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="leasePaymentsBeforeAmount">
                        <Form.Label>
                            14. What is the value of any lease incentive payments received on
                            or before lease commencement (if any)?{" "}
                            <LeaseTooltip tooltip={tooltips.tt14} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.leasePaymentsBeforeAmount}
                                {...props.register("leasePaymentsBeforeAmount", {
                                    required: requiredMessage,
                                })}
                                type="number"
                                step="0.01"
                                min="0.00"
                                isInvalid={!!props.errors.leasePaymentsBeforeAmount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.leasePaymentsBeforeAmount?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="leasePaymentsAfterAmount">
                        <Form.Label>
                            15. What is the expected amount of lease incentive payments to be
                            received after lease commencement (if any)?{" "}
                            <LeaseTooltip tooltip={tooltips.tt15} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.leasePaymentsAfterAmount}
                                {...props.register("leasePaymentsAfterAmount", {
                                    required: requiredMessage,
                                })}
                                type="number"
                                step="0.01"
                                min="0.00"
                                isInvalid={!!props.errors.leasePaymentsAfterAmount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.leasePaymentsAfterAmount?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="paymentPeriodFuturePayments">
                        <Form.Label>
                            16. In what payment period do you expect the future lease
                            incentive payment will be received?{" "}
                            <LeaseTooltip tooltip={tooltips.tt16} />
                        </Form.Label>
                        <Form.Control
                            defaultValue={props.data?.paymentPeriodFuturePayments}
                            {...props.register("paymentPeriodFuturePayments", {})}
                            type="number"
                            isInvalid={!!props.errors.paymentPeriodFuturePayments}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.errors.paymentPeriodFuturePayments?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="percentPaymentsRightToUse">
                        <Form.Label>
                            17. What is the percent of periodic lease payments is for the
                            right to use the leased asset (vs. for nonlease components, such
                            as common area maintenance, or CAM)?
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>%</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.percentPaymentsRightToUse}
                                {...props.register("percentPaymentsRightToUse", {
                                    min: { value: 0, message: "Must be greater than 0" },
                                    max: {
                                        value: 100,
                                        message: "Must not be greater than 100",
                                    },
                                })}
                                type="number"
                                isInvalid={!!props.errors.percentPaymentsRightToUse}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.percentPaymentsRightToUse?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Form.Group className="mb-5" controlId="leasePaymentsAmount">
                        <Form.Label>
                            18. What are the lease payments?{" "}
                            <LeaseTooltip tooltip={tooltips.tt18} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.leasePaymentsAmount}
                                {...props.register("leasePaymentsAmount", {
                                    required: requiredMessage,
                                })}
                                type="number"
                                step="0.01"
                                min="0.00"
                                isInvalid={!!props.errors.leasePaymentsAmount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.leasePaymentsAmount?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-5" controlId="leasePaymentComponentAmount">
                        <Form.Label>
                            18a. Lease payment component{" "}
                            <LeaseTooltip tooltip={tooltips.tt18a} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.leasePaymentComponentAmount}
                                {...props.register("leasePaymentComponentAmount", {
                                    required: requiredMessage,
                                })}
                                type="number"
                                step="0.01"
                                isInvalid={!!props.errors.leasePaymentComponentAmount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.leasePaymentComponentAmount?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group
                        className="mb-5"
                        controlId="nonLeasePaymentComponentAmount"
                    >
                        <Form.Label>
                            18b. Non-Lease payment component{" "}
                            <LeaseTooltip tooltip={tooltips.tt18b} />
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control
                                defaultValue={props.data?.nonLeasePaymentComponentAmount}
                                {...props.register("nonLeasePaymentComponentAmount", {
                                    required: requiredMessage,
                                })}
                                type="number"
                                step="0.01"
                                isInvalid={!!props.errors.nonLeasePaymentComponentAmount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.errors.nonLeasePaymentComponentAmount?.message}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
        </div>
    );
}

export default FacilityQuestions;
